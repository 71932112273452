import cloneDeep from "lodash/cloneDeep";
import { useEffect, useRef, useState } from "react";

const MathPuzzleStorageKey = "math-puzzle-test";

export default function MathPuzzleMaker(): JSX.Element {
  const puzzlePreviewRef = useRef(
    document.querySelector("#math-puzzle-preview")
  );
  const [isPreview, setIsPreview] = useState(true);

  const tooglePreview = () => {
    setIsPreview(!isPreview);
  };

  const toggleSelectedCell = () => {
    puzzlePreviewRef.current?.toggleFocusedCell();
  };

  const savePuzzle = () => {
    localStorage.setItem(
      MathPuzzleStorageKey,
      JSON.stringify(puzzlePreviewRef.current?.currentValue)
    );
  };

  const loadSavedPuzzle = () => {
    const savedConfig = localStorage.getItem(MathPuzzleStorageKey);
    if (savedConfig) {
      puzzlePreviewRef.current?.setAttribute("config", savedConfig);
    }
  };

  useEffect(() => {
    /*const config = puzzlePreviewRef.currentValue;
    const newConfig = config.map((row) => row.map((cell) => ({
        ...cell,
        value: cell.isHidden ? undefined : cell.value,
    })));*/

    puzzlePreviewRef.current?.setAttribute(
      "mode",
      isPreview ? "preview" : "puzzle"
    );
    //puzzlePreviewRef.current?.setAttribute("config", );
  }, [isPreview]);

  return (
    <div>
      <button className="btn btn-primary" onClick={toggleSelectedCell}>
        Toggle selected cell
      </button>{" "}
      <button className="btn btn-primary" onClick={tooglePreview}>
        Toggle mode
      </button>{" "}
      <button className="btn btn-primary" onClick={savePuzzle}>
        Save to storage
      </button>{" "}
      <button className="btn btn-primary" onClick={loadSavedPuzzle}>
        Load from storage
      </button>
    </div>
  );
}
