import { TextareaHTMLAttributes } from "react";

export type TextAreaInputProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  onChange: (value: string) => void;
  value: string;
};

export default function TextAreaInput({
  onChange,
  value,
  ...rest
}: TextAreaInputProps): JSX.Element {
  const handleChange = (e) => onChange(e.target.value);

  return <textarea value={value} onChange={handleChange} {...rest} />;
}
