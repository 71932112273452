import { InputHTMLAttributes } from "react";

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange: (value: string) => void;
  value: string;
};

export default function TextInput({
  onChange,
  value,
  ...rest
}: TextInputProps): JSX.Element {
  const handleChange = (e) => onChange(e.target.value);

  return <input type="text" value={value} onChange={handleChange} {...rest} />;
}
