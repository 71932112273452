import { SIBLINGS_MIN_GAP } from "../constants";

export function isSibling(p1, p2) {
  const intersection = getIntersectionArea(p1, p2);
  return (
    intersection.w >= 0 &&
    intersection.h >= 0 &&
    (intersection.w > p1.w * SIBLINGS_MIN_GAP ||
      intersection.h > p1.h * SIBLINGS_MIN_GAP)
  );
}

export function getIntersectionArea(p1, p2) {
  const x = Math.max(p1.X, p2.X);
  const y = Math.max(p1.Y, p2.Y);
  const w = Math.min(p1.X + p1.w, p2.X + p2.w) - x;
  const h = Math.min(p1.Y + p1.h, p2.Y + p2.h) - y;
  return { x, y, w, h };
}

export function getIntersectionPoint(p1, p2) {
  const intersection = getIntersectionArea(p1, p2);
  return {
    x: Math.round(intersection.x + intersection.w / 2),
    y: Math.round(intersection.y + intersection.h / 2),
  };
}

export function getRelativeIntersectionPoint(p1, p2) {
  const intersectionPoint = getIntersectionPoint(p1, p2);
  return {
    x: intersectionPoint.x - p1.X,
    y: intersectionPoint.y - p1.Y,
  };
}
