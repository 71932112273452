import { useState } from "react";

export default function useBooleanState(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  return [
    value,
    () => setValue(true),
    () => setValue(false),

    (newValue?) => setValue(Boolean(newValue ?? !value)),
  ];
}
