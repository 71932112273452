import { POOL_SIZE, IMAGE_MULTIPLIER, PREVIEW_GAP } from "../constants";

export default function getScale(
  areaWidth,
  areaHeight,
  width,
  height,
  includePoolSize = true
) {
  const poolSize = includePoolSize ? POOL_SIZE : 0;
  return Number(
    Math.min(
      Math.min(
        (areaWidth - 2 * poolSize) * IMAGE_MULTIPLIER,
        width * (1 + PREVIEW_GAP)
      ) /
        (width * (1 + PREVIEW_GAP)),
      Math.min(
        (areaHeight - 2 * poolSize) * IMAGE_MULTIPLIER,
        height * (1 + PREVIEW_GAP)
      ) /
        (height * (1 + PREVIEW_GAP))
    ).toFixed(2)
  );
}
