import baseFieldHOC from "./BaseFieldHOC";
import NumberInput from "./inputs/NumberInput";
import SelectInput from "./inputs/SelectInput";
import TextInput from "./inputs/TextInput";
import TextareaInput from "./inputs/TextareaInput";

const SelectField = baseFieldHOC(SelectInput);
const TextField = baseFieldHOC(TextInput);
const TextareaField = baseFieldHOC(TextareaInput);
const NumberField = baseFieldHOC(NumberInput);

export { SelectField, TextField, TextareaField, NumberField };
