import React, { useMemo } from "react";

import cx from "classnames";
import { FieldRenderProps } from "react-final-form";

import styles from "./BaseFieldLayout.module.scss";

export interface BaseFieldLayoutProps {
  label?: React.ReactNode;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  wrapperClassName?: string;
  inputClassName?: string;
}

export default function BaseFieldLayout({
  label,
  required = false,
  inputComponent: InputComponent,
  meta,
  input,
  disabled = false,
  placeholder = label,
  wrapperClassName,
  inputClassName,
  name,
  ...rest
}: FieldRenderProps<BaseFieldLayoutProps>): JSX.Element {
  const error = useMemo(() => {
    if (meta.submitError && !meta.dirtySinceLastSubmit) {
      return meta.submitError;
    }
    if (meta.error && meta.touched) {
      return meta.error;
    }
  }, [meta.error, meta.touched, meta.dirtySinceLastSubmit, meta.submitError]);
  const formattedError = useMemo(
    () => (Array.isArray(error) ? error[0] : error),
    [error]
  );

  return (
    <div
      className={cx(
        "form-group",
        styles.wrapper,
        wrapperClassName,
        `jigsaw__field--${input.name}`
      )}
    >
      {label && (
        <label htmlFor={`input-${input.name}`}>
          {label}
          {required && <span className="control-asterisk">*</span>}
        </label>
      )}
      <InputComponent
        className={cx("form-control", inputClassName)}
        required={required}
        id={`input-${input.name}`}
        {...rest}
        {...input}
      />
      {formattedError && (
        <small className="form-text text-danger">{formattedError}</small>
      )}
    </div>
  );
}
