import { useEffect, useRef, useState } from "react";
import range from "lodash/range";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import Toolbar from "components/Toolbar";

type MathPuzzleViewerProps = {
  config?: Record<string, any>;
  backgroundImage?: string;
  backgroundColor?: string;
  bordersColor?: string;
  cellSize?: number;
  isPreview?: boolean;
  onChange?: (value: Record<string, any>) => void;
  onFocus?: (cell: { x: number; y: number }) => void;
};

// prettier-ignore
const toolbarSymbols = [
  "+", "-", "±",
  ["×", "·", "*"],
  ["÷", "∶", "∕"],
  "=", "≈", "≠", "≡",
  "(", ")", "[", "]", "{", "}",
  "<", ">", "⩽", "⩾", "≪", "≫",
  "~", "√", "!", "∫",
  ["↑", "^", "**"],
  ["sin", "cos", "tg", "ctg", "sec", "cosec"],
  "∝", "∞",
]

export default function MathPuzzleViewer({
  config,
  backgroundImage,
  backgroundColor = "#fff",
  bordersColor = "#000",
  cellSize = 40,
  isPreview = false,
  onChange,
  onFocus,
}: MathPuzzleViewerProps): JSX.Element {
  const [puzzleState, setPuzzleState] = useState(config || []);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEmpty(puzzleState)) {
      const W = Math.floor(containerRef.current?.clientWidth / cellSize);
      const H = Math.floor(containerRef.current?.clientHeight / cellSize);

      const puzzleState = range(0, W).map((x) =>
        range(0, H).map((y) => ({
          isDisabled: false,
          value: undefined,
          isError: false,
          isHidden: false,
          solvedValue: undefined,
        }))
      );

      setPuzzleState(puzzleState);
    }
  }, []);

  useEffect(() => {
    if (config?.length > 0) {
      setPuzzleState(config);
    }
  }, [JSON.stringify(config)]);

  const handleFocus = (e) => {
    let { x, y } = e.target.dataset;
    x = Number(x);
    y = Number(y);
    onFocus?.({ x, y });
  };

  const handleChange = (x, y, value) => {
    if (parseInt(value) == value) {
      value = parseInt(value);
    }

    const newPuzzleState = cloneDeep(puzzleState);

    if (isPreview) {
      newPuzzleState[x][y].value = value;
    } else {
      newPuzzleState[x][y].solvedValue = value;
    }

    setPuzzleState(newPuzzleState);
  };

  const handleInputChange = (e) => {
    let { x, y } = e.target.dataset;
    let value = e.target.value;
    handleChange(Number(x), Number(y), value);
  };

  const handleNavigation = (e) => {
    let { x, y } = e.target.dataset;
    x = Number(x);
    y = Number(y);

    let newX = x;
    let newY = y;

    const maxY = puzzleState[0].length - 1;
    const maxX = puzzleState.length - 1;

    if (e.defaultPrevented) {
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        newY = Math.min(y + 1, maxY);
        break;
      case "ArrowUp":
        newY = Math.max(y - 1, 0);
        break;
      case "ArrowLeft":
        if (e.currentTarget.selectionStart === 0) {
          newX = Math.max(x - 1, 0);
        }
        break;
      case "ArrowRight":
        if (e.currentTarget.selectionEnd === e.currentTarget.value.length) {
          newX = Math.min(x + 1, maxX);
        }
        break;
      case "Enter":
      case "Tab":
      case " ":
        newX = Math.min(x + 1, maxX);
        if (newX === x) {
          newY = Math.min(y + 1, maxY);
          if (newY !== y) {
            newX = 0;
          }
        }
        break;
      case "Backspace":
        if (e.currentTarget.value.length === 0) {
          newX = Math.max(x - 1, 0);
          if (newX === x) {
            newY = Math.max(y - 1, 0);
            if (newY !== y) {
              newX = maxX;
            }
          }
        }
        break;
    }

    if (newX !== x || newY !== y) {
      e.preventDefault();
      const nextInput = containerRef.current.querySelector(
        `input[data-x="${newX}"][data-y="${newY}"]`
      );
      nextInput.focus();
      nextInput.select();
    }
  };

  const handleToolbarSelect = (value) => {
    const target = containerRef.current.querySelector(
      "input:focus"
    ) as HTMLInputElement;
    if (target) {
      let { x, y } = target.dataset;
      handleChange(Number(x), Number(y), value);
    }
  };

  useEffect(() => {
    if (!isEmpty(puzzleState)) {
      onChange?.(puzzleState);
    }
  }, [puzzleState]);

  return (
    <>
      <style>
        {`
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${backgroundColor};
  background-image: url(${backgroundImage});
}

.column {
  display: flex;
  flex-direction: column;
}

.cell {
  border: 1px solid #000;
  width: ${cellSize}px;
  height: ${cellSize}px;
  border-color: ${bordersColor};
  box-sizing: border-box;
}

.input {
    width: 100%;
    height: 100%;
    border: none;
    outline: 0px solid transparent;
    font-size: ${cellSize / 2}px;
    text-align: center;
    color: #000;
    background: transparent;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 5px;
}

input:focus {
    outline: 4px solid ${bordersColor};
}
        `}
      </style>
      <Toolbar onSelect={handleToolbarSelect}>{toolbarSymbols}</Toolbar>
      <div ref={containerRef} className="wrapper">
        {puzzleState.map((row, x) => (
          <div key={x} className="column">
            {row.map((cell, y) => (
              <div key={y} className="cell">
                <input
                  type="text"
                  className="input"
                  value={
                    isPreview || !cell.isHidden
                      ? cell.value ?? ""
                      : cell.solvedValue ?? ""
                  }
                  onChange={handleInputChange}
                  data-x={x}
                  data-y={y}
                  readOnly={isPreview ? cell.isDisabled : !cell.isHidden}
                  style={{
                    color: cell.isError ? "red" : "inherit",
                    //opacity: cell.isHidden ? 0.5 : 1,
                    outlineColor: cell.isHidden ? "red" : bordersColor,
                    outlineWidth: cell.isHidden ? 4 : "",
                  }}
                  onFocus={handleFocus}
                  maxLength={1}
                  onKeyDown={handleNavigation}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}
