import range from "lodash/range";
import sampleSize from "lodash/sampleSize";
import flatten from "lodash/flatten";
import { PIECE_LIP_LENGTH, PIECE_LIP_SIZE } from "../constants";

export default function slice(W, H, N) {
  let b = Math.sqrt((N * H) / W);
  let a = (b * W) / H;
  a = Math.round(a);
  b = Math.round(b);
  let extraCount;
  while ((extraCount = N - a * b) < 0) {
    if (a > b) {
      a--;
    } else {
      b--;
    }
  }

  let [w, h] = [W, H];
  if (H > W) {
    [a, b] = [b, a];
    [w, h] = [h, w];
  }

  let lines = range(a);
  const extraLines = sampleSize(lines, extraCount);
  const extraW = w % a;
  const sizeW = (w - extraW) / a;
  let minSize = sizeW;
  let results = lines
    .map((i) => (extraLines.includes(i) ? b + 1 : b))
    .map((count, i) => {
      const extraH = h % count;
      const sizeH = (h - extraH) / count;

      minSize = Math.min(minSize, sizeH);

      return range(count).map((j) => {
        return {
          x: i * sizeW + (i < extraW ? i : extraW),
          y: j * sizeH + (j < extraH ? j : extraH),
          w: sizeW + (i < extraW ? 1 : 0),
          h: sizeH + (j < extraH ? 1 : 0),
        };
      });
    });

  if (H > W) {
    results = results.map((line) =>
      line.map(({ x, y, w, h }) => ({
        x: y,
        y: x,
        w: h,
        h: w,
      }))
    );
  }

  return {
    pieces: flatten(results),
    lipSize: minSize * PIECE_LIP_SIZE,
    lipLength: minSize * PIECE_LIP_LENGTH,
  };
}
