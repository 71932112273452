import { InputHTMLAttributes } from "react";

export type NumberInputProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange: (value: number) => void;
  value: number;
};

export default function NumberInput({
  onChange,
  value,
  ...rest
}: NumberInputProps): JSX.Element {
  const handleChange = (e) => onChange(Number(e.target.value));

  return (
    <input value={value} onChange={handleChange} {...rest} type="number" />
  );
}
