import { createRoot } from "react-dom/client";
import JigsawPuzzleMakerForm from "components/JigsawPuzzleMakerForm";
import MathPuzzleMaker from "puzzles/math/MathPuzzleMaker";
import "puzzles/jigsaw/index.tsx";
import "puzzles/math/index.tsx";

const jigsawRoot = createRoot(document.getElementById("jigsaw-root"));
jigsawRoot.render(<JigsawPuzzleMakerForm />);

const mathRoot = createRoot(document.getElementById("math-root"));
mathRoot.render(<MathPuzzleMaker />);
