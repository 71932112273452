import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",
  initialState: { image: null, size: 100, lipSize: 10, lipLength: 10 },
  reducers: {
    setConfig(state, action) {
      state.image = action.payload.image;
      state.size = action.payload.size;
      state.lipSize = action.payload.lipSize;
      state.lipLength = action.payload.lipLength;
    },
    resetConfig() {
      return { image: null, size: 100, lipLength: 10, lipSize: 10 };
    },
  },
});

export const { setConfig, resetConfig } = configSlice.actions;
export default configSlice.reducer;
