import * as PIXI from "pixi.js";
import orderBy from "lodash/orderBy";
import { IMAGE_MULTIPLIER, POOL_SIZE, PREVIEW_GAP } from "./constants";
import getScale from "utils/getScale";
import every from "lodash/every";

export default function createRenderer({ container }) {
  const app = new PIXI.Application({ antialias: true, resizeTo: container });

  app.stage.eventMode = "static";
  app.stage.hitArea = app.screen;

  // we want to manually controll default behavior
  app.renderer.events.autoPreventDefault = false;
  // remove default pixi css rule that prevents touch events
  app.renderer.view.style.touchAction = "manipulation";

  // disable pointer events
  // firstly remove old events
  const canvas = app.renderer.events.domElement;
  app.renderer.events.removeEvents();
  // this tells the renderer to use touch events instead
  app.renderer.events.supportsPointerEvents = false;

  // save original event to cache as pixi doesn't provide it: https://github.com/pixijs/pixijs/issues/10024
  const eventsCache = new WeakMap();
  canvas.addEventListener(
    "touchstart",
    (e) => {
      Array.from(e.changedTouches ?? []).forEach((touch) => {
        eventsCache.set(touch, e);
      });
    },
    { capture: true, passive: false }
  );
  canvas.addEventListener(
    "touchmove",
    (e) => {
      Array.from(e.changedTouches ?? []).forEach((touch) => {
        eventsCache.set(touch, e);
      });
    },
    { capture: true, passive: false }
  );

  // now tell pixi to re-register all events
  app.renderer.events.setTargetElement(canvas);

  const centeredContainer = new PIXI.Container();
  app.stage.addChild(centeredContainer);

  const piecesContainer = new PIXI.Container();
  //centeredContainer.addChild(piecesContainer);

  const piecesCache = {};
  let texture;
  let lipSize;
  let lipLength;
  let isPreview;
  let onDragMoveCallback;
  let onDragEndCallback;
  let innerBackground;
  let outerBackground;
  let bordersColor;

  function clear() {
    Object.keys(piecesCache).forEach((uuid) => {
      piecesContainer.removeChild(piecesCache[uuid]);
      delete piecesCache[uuid];
    });

    if (texture) {
      // destroy the previous sprite
      texture.destroy();
    }

    centeredContainer.removeChildren();
  }

  async function setup({
    image,
    lipSize: newLipSize,
    lipLength: newLipLength,
    isPreview: newIsPreview = false,
    onDragMove: newOnDragMoveCallback,
    onDragEnd: newOnDragEndCallback,
    innerBackground: newInnerBackground,
    outerBackground: newOuterBackground,
    bordersColor: newBordersColor,
  }) {
    clear();
    await PIXI.Assets.reset();
    texture = await PIXI.Assets.load(image);
    lipSize = newLipSize;
    lipLength = newLipLength;
    isPreview = newIsPreview;
    onDragMoveCallback = newOnDragMoveCallback;
    onDragEndCallback = newOnDragEndCallback;
    innerBackground = newInnerBackground ?? 0x000000;
    outerBackground = newOuterBackground ?? 0x008080;
    bordersColor = newBordersColor ?? 0xffffff;

    app.renderer.background.color = innerBackground;

    const { width: areaWidth, height: areaHeight } = app.screen;
    const { width, height } = texture;
    const scale = getScale(areaWidth, areaHeight, width, height, !isPreview);

    centeredContainer.scale.set(scale);
    centeredContainer.x = app.screen.width / 2;
    centeredContainer.y = app.screen.height / 2;
    piecesContainer.x = -width / 2;
    piecesContainer.y = -height / 2;

    if (!isPreview) {
      let ch = areaHeight / scale;
      let cw = areaWidth / scale;
      let vps = (ch - height / IMAGE_MULTIPLIER) / 2;
      let hps = (cw - width / IMAGE_MULTIPLIER) / 2;

      // vizualize the pool area
      centeredContainer
        .addChild(
          new PIXI.Graphics()
            .beginFill(outerBackground, 1)
            .drawRect(0 - cw / 2, 0 - ch / 2, cw - hps, vps)
            .endFill()
        )
        .addChild(
          new PIXI.Graphics()
            .beginFill(outerBackground, 1)
            .drawRect(0 + cw / 2 - hps, 0 - ch / 2, hps, ch - vps)
            .endFill()
        )
        .addChild(
          new PIXI.Graphics()
            .beginFill(outerBackground, 1)
            .drawRect(0 - cw / 2 + hps, 0 + ch / 2 - vps, cw - hps, vps)
            .endFill()
        )
        .addChild(
          new PIXI.Graphics()
            .beginFill(outerBackground, 1)
            .drawRect(0 - cw / 2, 0 - ch / 2 + vps, hps, ch - vps)
            .endFill()
        )
        .addChild(piecesContainer);
    }

    centeredContainer.addChild(piecesContainer);

    return Promise.resolve();
  }

  function destroy() {
    app.destroy();
  }

  const activePieces = new Set();
  let activePiecesTimeout;
  function renderPiece(piece) {
    clearTimeout(activePiecesTimeout);
    activePieces.add(piece.uuid);
    activePiecesTimeout = setTimeout(clearUnactivePieces);
    return piecesCache[piece.uuid] ? update(piece) : create(piece);
  }

  function clearUnactivePieces() {
    Object.keys(piecesCache).forEach((uuid) => {
      if (!activePieces.has(uuid)) {
        piecesContainer.removeChild(piecesCache[uuid]);
        delete piecesCache[uuid];
      }
    });
    activePieces.clear();
  }

  function drawCurve(element, points) {
    let i = 1;
    for (i = 1; i < points.length - 2; i++) {
      const xc = (points[i][0] + points[i + 1][0]) / 2;
      const yc = (points[i][1] + points[i + 1][1]) / 2;
      element.quadraticCurveTo(points[i][0], points[i][1], xc, yc);
    }

    // curve through the last two points
    element.quadraticCurveTo(
      points[i][0],
      points[i][1],
      points[i + 1][0],
      points[i + 1][1]
    );
  }

  function drawLip(element, lip, side) {
    const L = lipLength;
    const S = lipSize;
    const G = S / 4;

    const { x, y, direction } = lip;

    const pointsMap = {
      "top-bottom": [
        [x - S / 2, y],
        [x - S / 2 + G, y],
        [x - S / 2, y + G],
        [x - S / 2, y + L],
        [x + S / 2, y + L],
        [x + S / 2, y + G],
        [x + S / 2 - G, y],
        [x + S / 2, y],
      ],
      "top-top": [
        [x - S / 2, y],
        [x - S / 2 + G, y],
        [x - S / 2, y - G],
        [x - S / 2, y - L],
        [x + S / 2, y - L],
        [x + S / 2, y - G],
        [x + S / 2 - G, y],
        [x + S / 2, y],
      ],
      "right-left": [
        [x, y - S / 2],
        [x, y - S / 2 + G],
        [x - G, y - S / 2],
        [x - L, y - S / 2],
        [x - L, y + S / 2],
        [x - G, y + S / 2],
        [x, y + S / 2 - G],
        [x, y + S / 2],
      ],
      "right-right": [
        [x, y - S / 2],
        [x, y - S / 2 + G],
        [x + G, y - S / 2],
        [x + L, y - S / 2],
        [x + L, y + S / 2],
        [x + G, y + S / 2],
        [x, y + S / 2 - G],
        [x, y + S / 2],
      ],
      "bottom-top": [
        [x + S / 2, y],
        [x + S / 2 - G, y],
        [x + S / 2, y - G],
        [x + S / 2, y - L],
        [x - S / 2, y - L],
        [x - S / 2, y - G],
        [x - S / 2 + G, y],
        [x - S / 2, y],
      ],
      "bottom-bottom": [
        [x + S / 2, y],
        [x + S / 2 - G, y],
        [x + S / 2, y + G],
        [x + S / 2, y + L],
        [x - S / 2, y + L],
        [x - S / 2, y + G],
        [x - S / 2 + G, y],
        [x - S / 2, y],
      ],
      "left-right": [
        [x, y + S / 2],
        [x, y + S / 2 - G],
        [x + G, y + S / 2],
        [x + L, y + S / 2],
        [x + L, y - S / 2],
        [x + G, y - S / 2],
        [x, y - S / 2 + G],
        [x, y - S / 2],
      ],
      "left-left": [
        [x, y + S / 2],
        [x, y + S / 2 - G],
        [x - G, y + S / 2],
        [x - L, y + S / 2],
        [x - L, y - S / 2],
        [x - G, y - S / 2],
        [x, y - S / 2 + G],
        [x, y - S / 2],
      ],
    };

    drawCurve(element, pointsMap[`${side}-${direction}`]);
  }

  function create(piece) {
    // Create a Graphics object, set a fill color, draw a rectangle
    const element = new PIXI.Graphics();
    piecesCache[(element.uuid = piece.uuid)] = element;
    if (isPreview) {
      element.lineStyle(2, bordersColor, 1);
    }
    //element.beginFill(0xffff00, 1.0);
    //element.beginFill([Math.random(), Math.random(), Math.random()]);
    element.beginTextureFill({
      texture,
      matrix: new PIXI.Matrix(1, 0, 0, 1, -piece.X, -piece.Y),
    });

    element.moveTo(0, 0);

    // top
    orderBy(
      piece.lips.filter((lip) => lip.y === 0),
      "x"
    ).forEach((lip) => {
      element.lineTo(lip.x - lipSize / 2, 0);
      drawLip(element, lip, "top");
    });
    element.lineTo(piece.w, 0);

    // right
    orderBy(
      piece.lips.filter((lip) => lip.x === piece.w),
      "y"
    ).forEach((lip) => {
      element.lineTo(piece.w, lip.y - lipSize / 2);
      drawLip(element, lip, "right");
    });
    element.lineTo(piece.w, piece.h);

    // bottom
    orderBy(
      piece.lips.filter((lip) => lip.y === piece.h),
      "x",
      "desc"
    ).forEach((lip) => {
      element.lineTo(lip.x + lipSize / 2, piece.h);
      drawLip(element, lip, "bottom");
    });
    element.lineTo(0, piece.h);

    // left
    orderBy(
      piece.lips.filter((lip) => lip.x === 0),
      "y",
      "desc"
    ).forEach((lip) => {
      element.lineTo(0, lip.y + lipSize / 2);
      drawLip(element, lip, "left");
    });
    element.lineTo(0, 0);

    element.eventMode = "static";
    piecesContainer.addChild(element);
    element.on("pointerdown", onDragStart);
    update(piece);
  }

  function update({ uuid, x, y }) {
    piecesCache[uuid].x = x;
    piecesCache[uuid].y = y;
  }

  function onDragStart(event) {
    this.data = event.data;
    this.offset = event.data.getLocalPosition(this);
    this.dragging = true;

    // bring to top
    piecesContainer.addChild(this);

    this.onDragEnd = onDragEnd.bind(this);
    this.onDragMove = onDragMove.bind(this);

    app.stage.on("pointerup", this.onDragEnd);
    app.stage.on("pointerupoutside", this.onDragEnd);
    app.stage.on("pointermove", this.onDragMove);
  }

  function onDragEnd() {
    this.dragging = false;
    this.data = null;
    this.offset = null;

    app.stage.off("pointerup", this.onDragEnd);
    app.stage.off("pointerupoutside", this.onDragEnd);
    app.stage.off("pointermove", this.onDragMove);

    onDragEndCallback?.({ uuid: this.uuid });
  }

  function onDragMove(e) {
    if (this.dragging) {
      const newPosition = this.data.getLocalPosition(this.parent);
      newPosition.x -= this.offset.x;
      newPosition.y -= this.offset.y;
      onDragMoveCallback?.({ uuid: this.uuid, ...newPosition });
      if (!isPreview && eventsCache.get(e.nativeEvent)) {
        eventsCache.get(e.nativeEvent).pleasePreventDefault = true;
      }
    }
  }

  app.stage.addEventListener("touchstart", onTouchStart);
  app.stage.addEventListener("touchmove", onTouchMove);
  app.stage.addEventListener("touchend", onTouchEnd);

  const touchCache = new Map();
  let propToCheck = null;
  let eventType = null;
  let startPivot = null;
  let pointUnderPinchCenter = null;
  const pinchThreshold = 3;

  function onTouchStart(e) {
    propToCheck = null;
    eventType = null;
    startPivot = null;
    pointUnderPinchCenter = null;
    const pointerId = e.pointerId;
    const oppositePointerId = Array.from(touchCache.keys()).find(
      (key) => key !== pointerId
    );
    const originalEvent = eventsCache.get(e.nativeEvent);
    touchCache.set(pointerId, [
      {
        x: e.global.x,
        y: e.global.y,
        e: originalEvent,
      },
    ]);
    if (
      oppositePointerId &&
      touchCache.get(oppositePointerId)[0].e !== originalEvent
    ) {
      // we have two fingers from different events
      touchCache.delete(oppositePointerId);
    }
    if (originalEvent.touches.length > 1) {
      // prevent zoom in for web page
      originalEvent.preventDefault();
    }
  }

  function onTouchMove(e) {
    const pointerId = e.pointerId;
    const oppositePointerId = Array.from(touchCache.keys()).find(
      (key) => key !== pointerId
    );
    if (!touchCache.get(pointerId)) {
      return;
    }
    touchCache.get(pointerId)[1] = {
      x: e.global.x,
      y: e.global.y,
    };

    if (
      !eventType &&
      touchCache.size === 1 &&
      touchCache.get(pointerId).length === 2
    ) {
      eventType = "move";
      startPivot = {
        x: app.stage.pivot.x,
        y: app.stage.pivot.y,
      };
    }

    if (
      !eventType &&
      touchCache.size === 2 &&
      every(Array.from(touchCache.values()), (value) => value.length === 2)
    ) {
      if (!propToCheck) {
        propToCheck =
          Math.abs(
            touchCache.get(pointerId)[0].x -
              touchCache.get(oppositePointerId)[1].x
          ) >
          Math.abs(
            touchCache.get(pointerId)[0].y -
              touchCache.get(oppositePointerId)[1].y
          )
            ? "x"
            : "y";
      }
      if (
        Math.abs(
          touchCache.get(pointerId)[0][propToCheck] -
            touchCache.get(pointerId)[1][propToCheck]
        ) +
          Math.abs(
            touchCache.get(oppositePointerId)[0][propToCheck] -
              touchCache.get(oppositePointerId)[1][propToCheck]
          ) >
        pinchThreshold
      ) {
        if (
          (touchCache.get(pointerId)[1][propToCheck] <
            touchCache.get(pointerId)[0][propToCheck] &&
            touchCache.get(oppositePointerId)[1][propToCheck] >
              touchCache.get(oppositePointerId)[0][propToCheck]) ||
          (touchCache.get(pointerId)[1][propToCheck] >
            touchCache.get(pointerId)[0][propToCheck] &&
            touchCache.get(oppositePointerId)[1][propToCheck] <
              touchCache.get(oppositePointerId)[0][propToCheck])
        ) {
          eventType = "pinch";
          pointUnderPinchCenter = {
            x:
              (touchCache.get(pointerId)[0].x +
                touchCache.get(oppositePointerId)[0].x) /
                (2 * app.stage.scale.x) +
              app.stage.pivot.x,
            y:
              (touchCache.get(pointerId)[0].y +
                touchCache.get(oppositePointerId)[0].y) /
                (2 * app.stage.scale.y) +
              app.stage.pivot.y,
          };
        } else {
          eventsCache.get(e.nativeEvent)?.preventDefault();
        }
      } else {
        eventsCache.get(e.nativeEvent)?.preventDefault();
      }
    }

    if (eventType === "pinch") {
      const startD = Math.abs(
        touchCache.get(pointerId)[0][propToCheck] -
          touchCache.get(oppositePointerId)[0][propToCheck]
      );
      const newD = Math.abs(
        touchCache.get(pointerId)[1][propToCheck] -
          touchCache.get(oppositePointerId)[1][propToCheck]
      );
      const delta = ((newD - startD) / startD) * 0.1 + 1;
      const minAllowedDeltaX = 1 / app.stage.scale.x;
      const minAllowedDeltaY = 1 / app.stage.scale.y;
      const maxAllowedDeltaX = 4 / app.stage.scale.x;
      const maxAllowedDeltaY = 4 / app.stage.scale.y;
      const allowedDelta = Math.min(
        Math.max(delta, minAllowedDeltaX, minAllowedDeltaY),
        maxAllowedDeltaX,
        maxAllowedDeltaY
      );

      app.stage.scale.set(
        app.stage.scale.x * allowedDelta,
        app.stage.scale.y * allowedDelta
      );

      const maxPivotX =
        allowedDelta <= 1
          ? Math.floor(
              (app.screen.width * (app.stage.scale.x - 1)) / app.stage.scale.x
            )
          : Infinity;
      const maxPivotY =
        allowedDelta <= 1
          ? Math.floor(
              (app.screen.height * (app.stage.scale.y - 1)) / app.stage.scale.y
            )
          : Infinity;

      const newPivotX = Math.min(
        maxPivotX,
        Math.max(
          0,
          pointUnderPinchCenter.x -
            getCenter(
              touchCache.get(pointerId)[0],
              touchCache.get(oppositePointerId)[0]
            ).x /
              app.stage.scale.x
        )
      );

      const newPivotY = Math.min(
        maxPivotY,
        Math.max(
          0,
          pointUnderPinchCenter.y -
            getCenter(
              touchCache.get(pointerId)[0],
              touchCache.get(oppositePointerId)[0]
            ).y /
              app.stage.scale.y
        )
      );

      if (delta > 1 || app.stage.scale.x > 1 || app.stage.scale.y > 1) {
        // do not prevent zoom out for web page
        eventsCache.get(e.nativeEvent)?.preventDefault();
      }

      // always prevent two fingers
      app.stage.pivot.set(newPivotX, newPivotY);
    }

    if (eventType === "move") {
      if (eventsCache.get(e.nativeEvent)?.pleasePreventDefault) {
        // moving piece
        eventsCache.get(e.nativeEvent)?.preventDefault();
        return;
      }

      const maxPivotX = Math.floor(
        (app.screen.width * (app.stage.scale.x - 1)) / app.stage.scale.x
      );
      const maxPivotY = Math.floor(
        (app.screen.height * (app.stage.scale.y - 1)) / app.stage.scale.y
      );

      const prevPivot = {
        x: app.stage.pivot.x,
        y: app.stage.pivot.y,
      };

      app.stage.pivot.set(
        Math.min(
          maxPivotX,
          Math.max(
            0,
            Math.round(
              startPivot.x +
                (touchCache.get(pointerId)[0].x -
                  touchCache.get(pointerId)[1].x) /
                  app.stage.scale.x
            )
          )
        ),
        Math.min(
          maxPivotY,
          Math.max(
            0,
            Math.round(
              startPivot.y +
                (touchCache.get(pointerId)[0].y -
                  touchCache.get(pointerId)[1].y) /
                  app.stage.scale.y
            )
          )
        )
      );

      const axis =
        Math.abs(app.stage.pivot.x - prevPivot.x) >
        Math.abs(app.stage.pivot.y - prevPivot.y)
          ? "x"
          : "y";
      const direction =
        app.stage.pivot[axis] - prevPivot[axis] > 0 ? "right" : "left";

      if (
        (axis === "x" &&
          direction === "right" &&
          app.stage.pivot.x === maxPivotX) ||
        (axis === "y" &&
          direction === "right" &&
          app.stage.pivot.y === maxPivotY) ||
        (axis === "x" && direction === "left" && app.stage.pivot.x === 0) ||
        (axis === "y" && direction === "left" && app.stage.pivot.y === 0)
      ) {
        // no more moves
      } else {
        // has moved
        eventsCache.get(e.nativeEvent)?.preventDefault();
      }
    }
  }

  function onTouchEnd(e) {
    touchCache.delete(e.pointerId);
    propToCheck = null;
    eventType = null;
    startPivot = null;
    pointUnderPinchCenter = null;
  }

  function getCenter(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  return {
    view: app.view,
    renderPiece,
    destroy,
    setup,
    clear,
  };
}
