import useBooleanState from "utils/useBooleanState";
import Toolbar from "./Toolbar";
import { useRef, useState } from "react";
import without from "lodash/without";

type ToolbarButtonProps = {
  children: string | string[];
  onSelect: (selectedValue) => void;
};

export default function ToolbarButton({
  children,
  onSelect,
}: ToolbarButtonProps): JSX.Element {
  const hasToolbar = Array.isArray(children);
  const [symbol, setSymbol] = useState(hasToolbar ? children[0] : children);
  const [isToolbarShown, , hideToolbar, toggleToolbar] = useBooleanState(false);
  const buttonRef = useRef(null);
  const handleClick = (e) => {
    if (hasToolbar) {
      toggleToolbar();
    }
    onSelect(symbol);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleSubSelect = (value) => {
    hideToolbar();
    setSymbol(value);
    onSelect(value);
  };

  return (
    <>
      {hasToolbar && isToolbarShown && (
        <Toolbar
          onSelect={handleSubSelect}
          bindTo={buttonRef}
          onClickOutside={hideToolbar}
        >
          {without(children, symbol)}
        </Toolbar>
      )}
      <button ref={buttonRef} onMouseDown={handleClick}>
        {symbol}
      </button>
    </>
  );
}
