import React from "react";

import { Field } from "react-final-form";

import BaseFieldLayout, {
  BaseFieldLayoutProps,
} from "./layouts/BaseFieldLayout";

// Workaround so that react-final-form never sets the value to null
// https://github.com/final-form/react-final-form/issues/130
function identity(value: any) {
  return value;
}

export default function baseFieldHOC<T>(Component: React.ComponentType<T>) {
  type AdditionalProps = {
    name: string;
  };
  const WrappedField = (
    props: Omit<T, "value" | "onChange"> &
      AdditionalProps &
      BaseFieldLayoutProps
  ) => {
    return (
      <Field
        component={BaseFieldLayout}
        inputComponent={Component}
        parse={identity}
        {...props}
      />
    );
  };
  return WrappedField;
}
