import { useRef, ReactNode, ButtonHTMLAttributes } from "react";
import cx from "classnames";

type UploadImageButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "type" | "onChange" | "children"
> & {
  children: ReactNode;
  onChange: (value: File) => void;
};

export default function UploadImageButton({
  onChange,
  children,
  className,
  ...restProps
}: UploadImageButtonProps): JSX.Element {
  const fileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => fileInput?.current?.click();
  const handleChange = (e) => onChange(e.target.files[0]);
  return (
    <>
      <input
        ref={fileInput}
        type="file"
        onChange={handleChange}
        accept="image/*"
        style={{ display: "none" }}
      />
      <button
        type="button"
        className={cx("btn btn-secondary", className)}
        onClick={handleClick}
        {...restProps}
      >
        {children}
      </button>
    </>
  );
}
