import { createRoot } from "react-dom/client";
import JigsawPuzzleViewer from "components/JigsawPuzzleViewer";
import debounce from "lodash/debounce";

class JigsawPuzzle extends HTMLElement {
  #mountPoint = null;
  #reactRoot = null;
  #rerender = null;
  currentValue = null;

  constructor() {
    super();

    this.#mountPoint = this.attachShadow({ mode: "closed" });
    this.#reactRoot = createRoot(this.#mountPoint);
    this.#rerender = debounce(this.#render.bind(this), 50);
  }

  connectedCallback() {
    this.#rerender();
  }

  static get observedAttributes() {
    return [
      "config",
      "inner-background",
      "outer-background",
      "borders-color",
      "size",
      "image",
      "mode",
    ];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this.#rerender();
  }

  #render() {
    const config = JSON.parse(this.getAttribute("config") || "{}");
    let size = Number(this.getAttribute("size"));
    if (isNaN(size)) size = 100;
    const image = this.getAttribute("image");
    const innerBackground = this.getAttribute("inner-background");
    const outerBackground = this.getAttribute("outer-background");
    const bordersColor = this.getAttribute("borders-color");
    const mode = this.getAttribute("mode");

    const onChange = (value) => {
      this.currentValue = value;
      this.dispatchEvent(
        new CustomEvent("change", {
          bubbles: true,
          composed: true,
          detail: value,
        })
      );
      if (value.isSolved) {
        this.dispatchEvent(
          new CustomEvent("solve", {
            bubbles: true,
            composed: true,
            detail: value,
          })
        );
      }
    };

    this.#reactRoot.render(
      <JigsawPuzzleViewer
        config={config}
        size={size}
        image={image}
        innerBackground={innerBackground}
        outerBackground={outerBackground}
        bordersColor={bordersColor}
        isPreview={mode === "preview"}
        onChange={onChange}
      />
    );
  }
}

customElements.define("jigsaw-puzzle", JigsawPuzzle);
