import Select, { Props as SelectProps } from "react-select";

export interface SelectInputProps extends SelectProps {
  onChange?: (value: string | number) => void;
  value: string | number;
  valueKey: string;
  labelKey: string;
}

export default function SelectInput({
  onChange,
  value,
  valueKey,
  labelKey,
  options,
  className,
  ...restProps
}: SelectInputProps): JSX.Element {
  const valueOption = options.find((option) => option[valueKey] === value);

  const handleChange = (option) => {
    onChange(option[valueKey]);
  };

  return (
    <Select
      options={options}
      value={valueOption}
      onChange={handleChange}
      getOptionLabel={(option) => option[labelKey]}
      getOptionValue={(option) => option[valueKey]}
      {...restProps}
      isSearchable={false}
    />
  );
}
