import { ReactNode, forwardRef } from "react";
import {
  Form as FinalForm,
  FormProps as FinalFormProps,
} from "react-final-form";

type FormValues = Record<string, any>;
type InitialFormValues = Partial<FormValues>;

export type FormProps = FinalFormProps<FormValues, InitialFormValues> & {
  onSubmit: (values: any) => void;
  children: ReactNode;
};

export default forwardRef(function Form(
  { onSubmit, children, ...restProps }: FormProps,
  ref
): JSX.Element {
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => {
        if (ref) {
          (ref as any).current = form;
        }
        return <form onSubmit={handleSubmit}>{children}</form>;
      }}
      {...restProps}
    />
  );
});
