import { v4 as uuidv4 } from "uuid";
import { createSlice } from "@reduxjs/toolkit";
import sample from "lodash/sample";
import uniq from "lodash/uniq";
import without from "lodash/without";
import {
  isSibling,
  getRelativeIntersectionPoint,
  getIntersectionArea,
} from "../utils/intersection";
import { CONNECTION_GAP } from "../constants";

const piecesSlice = createSlice({
  name: "pieces",
  initialState: [],
  reducers: {
    updatePiece(state, action) {
      const piece = state.find((piece) => piece.uuid === action.payload.uuid);
      Object.assign(piece, action.payload);
    },
    addPiece(state, action) {
      const siblings = state.filter((piece) =>
        isSibling(piece, action.payload)
      );
      let piece;
      state.push(
        (piece = {
          uuid: uuidv4(),
          X: action.payload.X,
          Y: action.payload.Y,
          w: action.payload.w,
          h: action.payload.h,
          x: action.payload.x,
          y: action.payload.y,
          connections: [],
          siblings: siblings.map((sibling) => sibling.uuid),
          lips: [],
        })
      );
      siblings.forEach((sibling) => {
        sibling.siblings.push(piece.uuid);
      });

      // calculate lips
      siblings.forEach((sibling) => {
        const area = getIntersectionArea(piece, sibling);
        const lip = getRelativeIntersectionPoint(piece, sibling);
        const direction =
          area.w > 0 ? sample(["top", "bottom"]) : sample(["left", "right"]);
        lip.direction = direction;
        lip.variant = "outset";
        if (
          (direction === "top" && lip.y > 0) ||
          (direction === "bottom" && lip.y === 0) ||
          (direction === "left" && lip.x > 0) ||
          (direction === "right" && lip.x === 0)
        ) {
          lip.variant = "inset";
        }
        piece.lips.push(lip);
        sibling.lips.push({
          ...getRelativeIntersectionPoint(sibling, piece),
          direction: lip.direction, // === 'left' ? 'right' : lip.direction === 'right' ? 'left' : lip.direction === 'top' ? 'bottom' : 'top',
          variant: lip.variant === "inset" ? "outset" : "inset",
        });
      });
    },
    movePiece(state, action) {
      const piece = state.find((piece) => piece.uuid === action.payload.uuid);
      const diff = {
        x: action.payload.x - piece.x,
        y: action.payload.y - piece.y,
      };
      piece.x += diff.x;
      piece.y += diff.y;
      // move connected pieces
      piece.connections.forEach((connectionUuid) => {
        const connection = state.find((piece) => piece.uuid === connectionUuid);
        connection.x += diff.x;
        connection.y += diff.y;
      });
    },
    moveEnd(state, action) {
      // find new connections
      const piece = state.find((piece) => piece.uuid === action.payload.uuid);

      const piecesToCheck = [piece].concat(
        piece.connections.map((connectionUuid) =>
          state.find((piece) => piece.uuid === connectionUuid)
        )
      );

      piecesToCheck.forEach((piece) => {
        piece.siblings.forEach((siblingUuid) => {
          const sibling = state.find((piece) => piece.uuid === siblingUuid);
          if (!piece.connections.includes(siblingUuid)) {
            const relativePosition = {
              x: sibling.x - piece.x,
              y: sibling.y - piece.y,
              X: sibling.X - piece.X,
              Y: sibling.Y - piece.Y,
            };
            const positionDiff = {
              x: Math.abs(relativePosition.x - relativePosition.X),
              y: Math.abs(relativePosition.y - relativePosition.Y),
            };
            if (
              positionDiff.x <= CONNECTION_GAP &&
              positionDiff.y <= CONNECTION_GAP
            ) {
              const connections = uniq([
                ...piece.connections,
                ...sibling.connections,
                siblingUuid,
                piece.uuid,
              ]);

              connections.forEach((connectionUuid) => {
                const connection = state.find(
                  (piece) => piece.uuid === connectionUuid
                );
                connection.connections = without(connections, connectionUuid);

                connection.x = piece.x + connection.X - piece.X;
                connection.y = piece.y + connection.Y - piece.Y;
              });
            }
          }
        });
      });
    },
    setPieces(state, action) {
      return action.payload;
    },
    clearAll() {
      return [];
    },
  },
});

export const {
  addPiece,
  movePiece,
  moveEnd,
  clearAll,
  updatePiece,
  setPieces,
} = piecesSlice.actions;
export default piecesSlice.reducer;
