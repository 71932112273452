import { createRoot } from "react-dom/client";
import MathPuzzleViewer from "./MathPuzzleViewer";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

class MathPuzzle extends HTMLElement {
  #mountPoint = null;
  #reactRoot = null;
  #rerender = null;
  currentValue = null;
  currentFocus = null;

  constructor() {
    super();

    this.#mountPoint = this.attachShadow({ mode: "closed" });
    this.#reactRoot = createRoot(this.#mountPoint);
    this.#rerender = debounce(this.#render.bind(this), 50);
  }

  connectedCallback() {
    this.#rerender();
  }

  static get observedAttributes() {
    return [
      "config",
      "background-image",
      "background-color",
      "borders-color",
      "cell-size",
      "mode",
    ];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!isEqual(oldValue, newValue)) {
      this.#rerender();
    }
  }

  toggleFocusedCell() {
    if (!this.currentFocus) {
      return;
    }

    const { x, y } = this.currentFocus;
    const newPuzzleState = cloneDeep(this.currentValue);
    newPuzzleState[x][y].isHidden = !newPuzzleState[x][y].isHidden;
    this.currentValue = newPuzzleState;
    this.setAttribute("config", JSON.stringify(newPuzzleState));
    this.#rerender();
  }

  #render() {
    const config = JSON.parse(this.getAttribute("config") || "[]");
    const mode = this.getAttribute("mode");
    const backgroundImage = this.getAttribute("background-image");
    const backgroundColor = this.getAttribute("background-color") ?? "#fff";
    const bordersColor = this.getAttribute("borders-color") ?? "#000";
    const cellSize = Number(this.getAttribute("cell-size") ?? 40);

    const onChange = (value) => {
      this.currentValue = value;
      const isSolved = value.every((row) =>
        row.every((cell) => !cell.isHidden || cell.value === cell.solvedValue)
      );
      this.dispatchEvent(
        new CustomEvent("change", {
          bubbles: true,
          composed: true,
          detail: value,
        })
      );
      if (isSolved) {
        this.dispatchEvent(
          new CustomEvent("solve", {
            bubbles: true,
            composed: true,
            detail: value,
          })
        );
      }
    };

    const onFocus = ({ x, y }) => {
      this.currentFocus = { x, y };
      this.dispatchEvent(
        new CustomEvent("focus", {
          bubbles: true,
          composed: true,
          detail: { x, y },
        })
      );
    };

    this.#reactRoot.render(
      <MathPuzzleViewer
        config={config}
        backgroundImage={backgroundImage}
        backgroundColor={backgroundColor}
        bordersColor={bordersColor}
        cellSize={cellSize}
        isPreview={mode === "preview"}
        onChange={onChange}
        onFocus={onFocus}
      />
    );
  }
}

customElements.define("math-puzzle", MathPuzzle);
