import random from "lodash/random";
import { IMAGE_MULTIPLIER, PIECE_LIP_LENGTH } from "../constants";
import getScale from "./getScale";

export default function getPoolPosition(
  areaWidth,
  areaHeight,
  imageWidth,
  imageHeight,
  pieceWidth,
  pieceHeight
) {
  const scale = getScale(areaWidth, areaHeight, imageWidth, imageHeight);
  const scaledWidth = areaWidth / scale;
  const scaledHeight = areaHeight / scale;
  const verticalPoolSize = (scaledHeight - imageHeight / IMAGE_MULTIPLIER) / 2;
  const horizontalPoolSize = (scaledWidth - imageWidth / IMAGE_MULTIPLIER) / 2;

  // get random position in pool - on perimeter around of image
  // pool perimeter position
  const ppp = random(
    0,
    2 * (scaledWidth + scaledHeight) -
      2 * horizontalPoolSize -
      2 * verticalPoolSize
  );
  const isTop = ppp < scaledWidth - horizontalPoolSize;
  const isRight =
    ppp >= scaledWidth - horizontalPoolSize &&
    ppp < scaledWidth - horizontalPoolSize + scaledHeight - verticalPoolSize;
  const isBottom =
    ppp >= scaledWidth - horizontalPoolSize + scaledHeight - verticalPoolSize &&
    ppp <
      2 * scaledWidth +
        scaledHeight -
        verticalPoolSize -
        2 * horizontalPoolSize;
  const isLeft = !(isTop || isRight || isBottom);
  const isHorizontal = isTop || isBottom;

  let x;
  let y;
  if (isHorizontal) {
    x =
      (isTop
        ? ppp
        : ppp -
          (scaledWidth +
            scaledHeight -
            verticalPoolSize -
            horizontalPoolSize)) -
      scaledWidth / 2;
    y = isTop
      ? random(
          0 - scaledHeight / 2 + pieceHeight * PIECE_LIP_LENGTH,
          0 -
            scaledHeight / 2 +
            verticalPoolSize -
            pieceHeight -
            pieceHeight * PIECE_LIP_LENGTH
        )
      : random(
          0 +
            scaledHeight / 2 -
            verticalPoolSize +
            pieceHeight * PIECE_LIP_LENGTH,
          0 + scaledHeight / 2 - pieceHeight - pieceHeight * PIECE_LIP_LENGTH
        );
  } else {
    x = isLeft
      ? random(
          0 - scaledWidth / 2 + pieceWidth * PIECE_LIP_LENGTH,
          0 -
            scaledWidth / 2 +
            horizontalPoolSize -
            pieceWidth -
            pieceWidth * PIECE_LIP_LENGTH
        )
      : random(
          0 +
            scaledWidth / 2 -
            horizontalPoolSize +
            pieceWidth * PIECE_LIP_LENGTH,
          0 + scaledWidth / 2 - pieceWidth - pieceWidth * PIECE_LIP_LENGTH
        );
    y =
      (isLeft
        ? ppp -
          (2 * scaledWidth +
            scaledHeight -
            2 * horizontalPoolSize -
            verticalPoolSize)
        : ppp - (scaledWidth - horizontalPoolSize)) -
      scaledHeight / 2;
  }

  return {
    x: x + imageWidth / 2,
    y: y + imageHeight / 2,
  };
}
