import { useEffect, useRef, useState } from "react";
import { SelectField, NumberField, Form } from "components/forms";
import UploadImageButton from "components/UploadImageButton";
import useImages from "utils/useImages";
import { FormSpy } from "react-final-form";
import styles from "./JigsawPuzzleMakerForm.module.css";

export default function JigsawPuzzleMakerForm(): JSX.Element {
  const puzzlePreviewRef = useRef(
    document.querySelector("#jigsaw-puzzle-preview")
  );
  const [isPreview, setIsPreview] = useState(true);
  const formRef = useRef(null);
  const onSubmit = (values) => {
    const config = isPreview ? values : puzzlePreviewRef.current?.currentValue;
    localStorage.setItem("jigsaw-puzzle", JSON.stringify(config));
  };

  const { images, addImage } = useImages();

  const handleImage = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64image = e.target.result.toString();
      addImage(base64image, file.name);
      formRef.current.change("image", base64image);
    };
    reader.onerror = (e) => null;
    reader.readAsDataURL(file);
  };

  const initialValues = {
    image: images[0]?.image,
    size: 100,
  };

  useEffect(() => {
    if (isPreview) {
      puzzlePreviewRef.current?.removeAttribute("config");
    }
    puzzlePreviewRef.current?.setAttribute(
      "mode",
      isPreview ? "preview" : "puzzle"
    );
  }, [isPreview]);

  const onFormChange = ({ values }) => {
    puzzlePreviewRef.current?.setAttribute("size", values.size);
    puzzlePreviewRef.current?.setAttribute("image", values.image);
    //puzzlePreviewRef.current?.setAttribute("config", JSON.stringify(values));
  };

  const tooglePreview = () => {
    //if(isPreview) {
    //  puzzlePreviewRef.current?.setAttribute("config", JSON.stringify(puzzlePreviewRef.current?.currentValue));
    //}
    setIsPreview(!isPreview);
  };

  const loadSavedPuzzle = () => {
    const config = JSON.parse(localStorage.getItem("jigsaw-puzzle") || "{}");
    console.log("load", config);
    formRef.current.change("image", config.image);
    formRef.current.change("size", config.size);
    puzzlePreviewRef.current?.setAttribute("config", JSON.stringify(config));
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} ref={formRef}>
      <div className={isPreview ? "" : styles.disabledForm}>
        <div className="row form-group">
          <div className="col-sm-8 jigsaw__col--image">
            <SelectField
              name="image"
              label="Image"
              options={images}
              labelKey="name"
              valueKey="image"
              wrapperClassName="flex-grow-1"
            />
            <UploadImageButton onChange={handleImage}>
              Upload image
            </UploadImageButton>
          </div>
          <div className="col-sm-4 jigsaw__col--size">
            <NumberField name="size" label="Size" max={1000} min={10} />
          </div>
        </div>
        <FormSpy subscription={{ values: true }} onChange={onFormChange} />
      </div>
      <div className="jigsaw__buttons">
        <button type="submit" className="btn btn-primary">
          Save to storage
        </button>{" "}
        <button
          type="button"
          className="btn btn-primary"
          onClick={loadSavedPuzzle}
        >
          Load from storage
        </button>{" "}
        <button
          type="button"
          className="btn btn-primary"
          onClick={tooglePreview}
        >
          Toggle to {isPreview ? "puzzle" : "editor"} mode
        </button>
      </div>
    </Form>
  );
}
