import { configureStore } from "@reduxjs/toolkit";
import config from "./config";
import pieces from "./pieces";

const INITIAL_DATA = {
  config: { image: "https://picsum.photos/1000/500", size: 4 },
  pieces: [
    {
      uuid: "c398da84-91fb-4023-ae0d-4bc6cc6981d2",
      X: 0,
      Y: 0,
      w: 334,
      h: 500,
      x: 240.1999969482422,
      y: 100,
      connections: [],
      siblings: [
        "159b7b2a-7fae-478f-81bd-5c92bcdd2bc3",
        "ce970c4f-76d6-4ef5-baf8-042e34f58fd4",
      ],
      lips: [
        { x: 334, y: 125, direction: "right", variant: "outset" },
        { x: 334, y: 375, direction: "right", variant: "outset" },
      ],
    },
    {
      uuid: "159b7b2a-7fae-478f-81bd-5c92bcdd2bc3",
      X: 334,
      Y: 0,
      w: 333,
      h: 250,
      x: 675.4000091552734,
      y: 52.79998779296875,
      connections: [],
      siblings: [
        "c398da84-91fb-4023-ae0d-4bc6cc6981d2",
        "ce970c4f-76d6-4ef5-baf8-042e34f58fd4",
        "58660954-7b14-4d21-98cc-fb3c996b03cc",
      ],
      lips: [
        { x: 0, y: 125, direction: "right", variant: "inset" },
        //{ x: 167, y: 250, direction: "bottom", variant: "outset" },
        //{ x: 333, y: 125, direction: "left", variant: "inset" },
      ],
    },
    {
      uuid: "ce970c4f-76d6-4ef5-baf8-042e34f58fd4",
      X: 334,
      Y: 250,
      w: 333,
      h: 250,
      x: 676.0000762939453,
      y: 423.5999755859375,
      connections: [],
      siblings: [
        "c398da84-91fb-4023-ae0d-4bc6cc6981d2",
        "159b7b2a-7fae-478f-81bd-5c92bcdd2bc3",
        "58660954-7b14-4d21-98cc-fb3c996b03cc",
      ],
      lips: [
        { x: 0, y: 125, direction: "right", variant: "inset" },
        { x: 167, y: 0, direction: "bottom", variant: "inset" },
        { x: 333, y: 125, direction: "right", variant: "outset" },
      ],
    },
    {
      uuid: "58660954-7b14-4d21-98cc-fb3c996b03cc",
      X: 667,
      Y: 0,
      w: 333,
      h: 500,
      x: 1103.9999694824219,
      y: 72.60006713867188,
      connections: [],
      siblings: [
        "159b7b2a-7fae-478f-81bd-5c92bcdd2bc3",
        "ce970c4f-76d6-4ef5-baf8-042e34f58fd4",
      ],
      lips: [
        { x: 0, y: 125, direction: "left", variant: "outset" },
        { x: 0, y: 375, direction: "right", variant: "inset" },
      ],
    },
  ],
};

export default {
  reducer: {
    config,
    pieces,
  },
  //preloadedState: INITIAL_DATA,
};
