import { RefObject, useEffect, useRef } from "react";
import ToolbarButton from "./ToolbarButton";
import cx from "classnames";
import { createPopper } from "@popperjs/core";

type ToolbarProps = {
  children: (string | string[])[];
  onSelect: (selectedValue) => void;
  bindTo?: RefObject<HTMLElement>;
  onClickOutside?: () => void;
};

export default function Toolbar({
  children,
  onSelect,
  bindTo,
  onClickOutside,
}: ToolbarProps): JSX.Element {
  const isFloating = Boolean(bindTo?.current);
  const containerRef = useRef(null);

  useEffect(() => {
    if (isFloating) {
      createPopper(bindTo.current, containerRef.current, {
        placement: "bottom",
      });
    }

    const handleClickOutside = (e) => {
      if (
        isFloating &&
        !containerRef.current?.contains(e.target) &&
        !bindTo.current?.contains(e.target)
      ) {
        onClickOutside?.();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFloating]);

  // TODO click outside

  return (
    <>
      <style>
        {`
.toolbar-container {
    display: flex;
    gap: 4px;
    padding: 4px 0;
    align-items: center;
    flex-wrap: wrap;
}
.toolbar-container-floating {
    max-width: ${isFloating ? "300px" : "auto"};
    background: #ffffff;
    padding: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}
        `}
      </style>
      <div
        className={cx(
          "toolbar-container",
          isFloating && "toolbar-container-floating"
        )}
        ref={containerRef}
      >
        {children.map((symbols) => (
          <ToolbarButton key={String(symbols)} onSelect={onSelect}>
            {symbols}
          </ToolbarButton>
        ))}
      </div>
    </>
  );
}
