import { memo, useEffect, useRef } from "react";
import { configureStore } from "@reduxjs/toolkit";
import createRenderer from "../../renderer";
import preloadImage from "utils/preloadImage";

import slice from "utils/slice";
import {
  addPiece,
  moveEnd,
  movePiece,
  clearAll,
  setPieces,
} from "store/pieces";
import { setConfig, resetConfig } from "store/config";
import shuffle from "lodash/shuffle";
import { PREVIEW_GAP } from "../../constants";
import getPoolPosition from "utils/getPoolPosition";

import storeConfig from "store";

type JigsawPuzzleViewerProps = {
  config?: Record<string, any>;
  size?: number;
  // base64 encoded image
  image: string;
  innerBackground?: string;
  outerBackground?: string;
  bordersColor?: string;
  isPreview?: boolean;
  onChange?: (value: Record<string, any>) => void;
};

export default memo(function JigsawPuzzleViewer({
  config = {},
  size = 100,
  image,
  innerBackground = "#000000",
  outerBackground = "#008080",
  bordersColor = "#ffffff",
  isPreview = false,
  onChange,
}: JigsawPuzzleViewerProps): JSX.Element {
  const { pieces, lipSize, lipLength } = isPreview ? {} : config;
  if (!isPreview) {
    size = config.size ?? size;
    image = config.image ?? image;
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const storeRef = useRef<any>(null);
  const rendererRef = useRef<any>(null);

  useEffect(() => {
    const store = (storeRef.current = configureStore({
      ...storeConfig,
      preloadedState: {
        pieces: pieces || [],
        config: {
          image,
          size,
          lipSize,
          lipLength,
        },
      },
    }));
    //store.subscribe(() => console.log(store.getState()));
    const renderer = (rendererRef.current = createRenderer({
      container: containerRef.current,
    }));
    containerRef.current.appendChild(renderer.view);

    return () => {
      renderer.destroy();
      containerRef.current?.removeChild(renderer.view);
    };
  }, []);

  useEffect(() => {
    const store = storeRef.current;
    const renderer = rendererRef.current;
    let unsubscribe;
    let refreshTimeout;
    let setupPromise;
    let isDisposed = false;
    const areaWidth = containerRef.current.clientWidth;
    const areaHeight = containerRef.current.clientHeight;
    const onDragMove = isPreview
      ? null
      : (data) => store.dispatch(movePiece(data));
    const onDragEnd = isPreview
      ? null
      : (data) => store.dispatch(moveEnd(data));
    preloadImage(image).then(({ width, height }) => {
      if (isDisposed) {
        return;
      }
      if (!pieces) {
        let { pieces, lipSize, lipLength } = slice(width, height, size);
        store.dispatch(setConfig({ image, size, lipLength, lipSize }));
        if (!isPreview) {
          pieces = shuffle(pieces);
        }
        pieces.forEach(({ x, y, w, h }) => {
          const pp = getPoolPosition(
            areaWidth,
            areaHeight,
            width,
            height,
            w,
            h
          );
          store.dispatch(
            addPiece({
              X: x,
              Y: y,
              w,
              h,
              x: isPreview ? x * (1 + PREVIEW_GAP) : pp.x,
              y: isPreview ? y * (1 + PREVIEW_GAP) : pp.y,
            })
          );
        });
        setupPromise = renderer.setup({
          image,
          lipSize,
          lipLength,
          isPreview,
          onDragMove,
          onDragEnd,
          innerBackground,
          outerBackground,
          bordersColor,
        });
      } else {
        store.dispatch(setConfig({ image, size, lipLength, lipSize }));
        store.dispatch(setPieces(pieces));
        setupPromise = renderer.setup({
          image,
          lipSize,
          lipLength,
          isPreview,
          onDragMove,
          onDragEnd,
          innerBackground,
          outerBackground,
          bordersColor,
        });
      }

      setupPromise.then(() => {
        if (isDisposed) {
          return;
        }
        unsubscribe = store.subscribe(() => {
          const { pieces, config } = store.getState();
          onChange?.({
            pieces,
            lipSize: config.lipSize,
            lipLength: config.lipLength,
            isSolved: pieces[0].connections.length === pieces.length - 1,
            image: config.image,
            size: config.size,
          });
          pieces.forEach(renderer.renderPiece);
        });
        refreshTimeout = setTimeout(
          () => store.dispatch({ type: "REFRESH" }),
          50
        );
      });
    });

    return () => {
      isDisposed = true;
      clearTimeout(refreshTimeout);
      unsubscribe?.();
      renderer.clear();
      store.dispatch(clearAll());
      store.dispatch(resetConfig());
    };
  }, [
    image,
    size,
    isPreview,
    pieces,
    innerBackground,
    outerBackground,
    bordersColor,
  ]);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
});
