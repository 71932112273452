import { useEffect, useState } from "react";

type ImageOption = {
  image: string;
  name: string;
};

export default function useImages() {
  const [images, setImages] = useState<ImageOption[]>(
    JSON.parse(localStorage.getItem("images")) || []
  );

  const addImage = (image, name) => {
    setImages(
      images.concat({
        image,
        name,
      })
    );
  };

  useEffect(() => {
    localStorage.setItem("images", JSON.stringify(images));
  }, [images]);

  return {
    images,
    addImage,
  };
}
